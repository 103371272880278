import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MenuPage} from '../menu/menu.page';
import {AppComponent} from '../../app.component';
import {TranslateService} from '@ngx-translate/core';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import { NavigationService, NAVIGATIONURLS } from 'src/app/services/navigation.service';

@Component({
	selector: 'app-order',
	templateUrl: './order.page.html',
	styleUrls: ['order.page.scss']
})
export class OrderPage extends RepositoryDirective implements OnInit {
	static url = 'order';
	static failUrlWithPaymentParam = 'payment/fail/:payment';
	static cancelUrlWithPaymentParam = 'payment/cancel/:payment';
	static paymentFailUrl = 'payment/fail';
	static paymentCancelUrl = 'payment/cancel';
	loading = false;

	constructor(
		protected repository: RepositoryService,
		private translate: TranslateService,
		private snackbarCtrl: MatSnackBar,
		private navigationService: NavigationService,
	) {
		super(repository);
	}

	get title(): string {
		return this.translate.instant('menu_page.basket_title');
	}

	ngOnInit(): void {
		super.ngOnInit();
		console.log('ngOnInit');
		switch (window.location.pathname) {
			case '/' + NAVIGATIONURLS.order():
				break;
				case '/' + NAVIGATIONURLS.paymentCancel:
					console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000
				});
				break;
				case '/' + NAVIGATIONURLS.paymentFail:
					console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000
				});
				break;
		}
	}

	async backClick() {
		await this.navigationService.menu()
	}
}
