import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import {DisplayIdentifier} from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {getAvailability, getPrice, numberToCurrency} from '../../../smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-article-option-group-single',
	templateUrl: './article-option-group-single.component.html',
	styleUrls: ['article-option-group-single.component.scss']
})
export class ArticleOptionGroupSingleComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Output() openInfo = new EventEmitter<Article>();
	@Input() preorderType: PreorderType;
	@Input() currency: string;
	selectedItem: Article;
	numberToCurrency = numberToCurrency;
	di = DisplayIdentifier;

	constructor() {}

	isHidden(article: Article): boolean {
		return this.preorderType && !getAvailability(article, OrderType.PREORDER, this.preorderType);
	}

	selectedOptionIndex() {
		const indexSelection = this.selected.findIndex(selectedOption => selectedOption.group === this.optionGroup._id);
		this.selectedItem = this.selected[0]?.article;
		if (indexSelection < 0) {
			return indexSelection;
		}
		return this.optionGroup.articles.findIndex(article => article._id === this.selected[indexSelection].article._id);
	}

	onSelectionChange(article: Article) {
		this.selectedItem = article;
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = 1;
		this.selection.emit(articleOption);
		console.log(this.selectedItem, article);
	}

	isDisabled(option: Article): boolean {
		return option.requirements && option.requirements.min > 0 && this.isOptionSelected(option);
	}

	price(option: Article): number {
		let price = getPrice(option, OrderType.PREORDER, this.preorderType);
		if (this.fullPrice()) {
			price += getPrice(this.article, OrderType.PREORDER, this.preorderType);
		}
		return price;
	}

	fullPrice(): boolean {
		return (
			this.optionGroup.displayIdentifiers &&
			this.optionGroup.displayIdentifiers.findIndex(di => di === DisplayIdentifier.fullPrice) >= 0
		);
	}

	isOptionSelected(option: Article): boolean {
		return (
			this.selected.find(sel => sel.article._id === option._id && sel.group === this.optionGroup._id) !== undefined
		);
	}

	openInfoClick(option: Article) {
		this.openInfo.emit(option);
	}
	selectedItemEqualsArticle(selectedItem: any) {
		console.log('CHANGES');
		if (selectedItem) {
			return (
				selectedItem?.name?.fr === this.article?.name?.fr ||
				selectedItem[0].article?.name?.fr === this.article?.name?.fr
			);
		} else {
			return false;
		}
	}
}
