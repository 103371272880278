import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const lacantineCustomerGroup = 'lacantine';
export const customerGroup = lacantineCustomerGroup;

const supportEmail = {
	lacantine: 'lacantine@smoothr.de'
};
const firebaseConfig = {
	lacantine: {
		apiKey: 'AIzaSyD2lNupuxAWeV74e_11Bq_4hTf4z1n57bg',
		authDomain: 'lacantine-825f4.firebaseapp.com',
		projectId: 'lacantine-825f4',
		storageBucket: 'lacantine-825f4.appspot.com',
		messagingSenderId: '185404610970',
		appId: '1:185404610970:web:b58ad90e2f896b48c8c223',
		measurementId: 'G-J1G1DKT709'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDx99_I34aUK3cLhF7zTI6Uxcq6FP9qKvA',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de'],
	gaId: ''
};

import 'zone.js/dist/zone-error';
