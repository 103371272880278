import {Component} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {PopoverController} from '@ionic/angular';
import {environment} from '../../../environments/environment';
import { NavigationService } from '../../../app/services/navigation.service';


@Component({
	selector: 'app-home-popover',
	templateUrl: './home-popover.component.html',
	styleUrls: ['home-popover.component.scss']
})
export class HomePopoverComponent {
	environment = environment;

	constructor(
		public repository: RepositoryService, 
		private popoverCtrl: PopoverController,
		private navigationService: NavigationService

		) {}

	async change() {
		await this.popoverCtrl.dismiss();
		await this.navigationService.home();
	}

	async dismiss() {
		await this.popoverCtrl.dismiss();
	}
}
