import { Component, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { IonCol } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import moment, { Moment } from 'moment';
import { TimeUtils } from 'src/smoothr-web-app-core/utils/time-utils';

const SEARCH_INPUT_CONTROL_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectDateFormcontrolComponent),
  multi: false,
};

@Component({
  selector: 'app-select-date-formcontrol',
  templateUrl: './select-date-formcontrol.component.html',
  styleUrls: ['./select-date-formcontrol.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDateFormcontrolComponent),
      multi: true
    }
  ],
})
export class SelectDateFormcontrolComponent implements ControlValueAccessor, OnInit {

  @ViewChild('timeCol', { static: true }) timeCol: IonCol;
  @Input() set slots(slots: Moment[]) {
    this.dates = [];
    this._slots = slots;
    this._slots.forEach(slot => {
      if (!slot) {
        this.onChange(null);
        return;
      }
      const insertedDate = this.dates.find(date =>
        TimeUtils.isSameDate(date, slot)
      );
      if (!insertedDate) {
        this.dates.push(slot);
      }
    });
  }


  onChange: any = () => { };
  onTouched: any = () => { };
  get slots(): Moment[] {
    return this._slots;
  }
  private _slots: Moment[] = [];
  @Input()
  set slot(slot: Moment) {
    this._slot = slot;
    this.onChange(slot);
  }
  get slot(): Moment {
    return this._slot;
  }
  private _slot: Moment;
  dates: Moment[] = [];
  now: moment.Moment;
  maxSlots = 5;
  maxDates = 3;
  TimeUtils = TimeUtils;



  constructor(private translate: TranslateService) { }

  ngOnInit(): void { }
  writeValue(value: number): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  slotToText(slot: Moment): string {
    if (!slot) {
      return null;
    }
    if (!moment.isMoment(slot)) {
      slot = moment(slot);
    }
    const now = moment();
    const sameDay = TimeUtils.isSameDate(slot, now);
    if (sameDay) {
      return slot.format('HH:mm');
    } else {
      return slot.format('HH:mm / DD.MM.yyyy');
    }
  }
  slotToDateText(slot: Moment): string {
    if (TimeUtils.isSameDate(slot, this.now)) {
      return this.translate.instant('today');
    }
    return slot.lang('de').format('dd DD.MM');
  }
  slotToTimeText(slot: Moment): string {
    if (!slot) return this.translate.instant('checkout_modal.first_slot');
    return slot.format('HH.mm');
  }
  timesForDate(selectedDate: Moment): Moment[] {
    const date = selectedDate ?? this.dates[0] ?? null;
    if (!date) {
      return [];
    }
    return this.slots.filter(
      slot =>
        TimeUtils.isSameDate(slot, date) || (!slot && date === this.dates[0])
    );
  }
  setDate(date: Moment) {
    this.slot = date;
    this.maxSlots = 5;
    this.scrollTimeToTop();
  }
  setTime(time: Moment) {
    this.slot = time;
  }
  async scrollTimeToTop() {
    // @ts-ignore
    const el: HTMLDivElement = this.timeCol.el;
    el.scrollTo({
      left: 0,
      top: 0
    });
  }
}
