import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {numberToCurrency} from 'src/smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import { ModalController } from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import { AddCustomTipComponent } from '../add-custom-tip/add-custom-tip.component';

@Component({
	selector: 'app-tip',
	templateUrl: './tip.component.html',
	styleUrls: ['tip.component.scss']
})
export class TipComponent implements OnInit {
	@Output()
	tip = new EventEmitter<number>();
	percents = [5, 10, 15];
	numberToCurrency = numberToCurrency;

	constructor(
		private modalCtrl: ModalController,
		private translate: TranslateService
	) {}

	private _totalPrice: number;

	get totalPrice(): number {
		return this._totalPrice;
	}

	set totalPrice(value: number) {
		if (this._totalPrice === value) {
			return;
		}
		this._totalPrice = value;
		this.recalculateAndEmitTip();
	}

	private _order: Order;

	get order(): Order {
		return this._order;
	}

	@Input()
	set order(order: Order) {
		this._order = order;
		this.totalPrice = OrderUtils.orderTotalPrice(order, true, true);
	}

	get currency(): string {
		return this.order && this.order.currency;
	}

	private _customTip: number;

	get customTip(): number {
		return this._customTip;
	}

	set customTip(value: number) {
		this._customTip = value;
		this.recalculateAndEmitTip();
	}

	private _selectedPercent: number = null;

	get selectedPercent(): number {
		return this._selectedPercent;
	}

	set selectedPercent(percent: number) {
		this._selectedPercent = percent;
		this.recalculateAndEmitTip();
	}

	ngOnInit() {
	}

	async showCustomPicker(): Promise<number> {
		let customTipValue = (this.customTip && this.customTip.toString().includes('.')) ? this.customTip.toString().replace('.',',') : (this.customTip ? this.customTip.toString(): "")
		let result = await AddCustomTipComponent.show(this.modalCtrl, customTipValue);
		if (!result) {
			return -1;
		}
		const selectedValue = Number(result.toString().replace(',','.'));
		this.customTip = selectedValue;
		this.selectedPercent = 0
		return selectedValue;
	}

	recalculateAndEmitTip() {
		
		if (this.customTip) {
			this.tip.emit(this.customTip);
			return;
		}
		if (this.selectedPercent > 0) {
			this.tip.emit(
				+(this.totalPrice * this.selectedPercent * 0.01).toFixed(2)
			);
			return;
		}
		this.tip.emit(0);
		return;
	}

	select(percent: number) {
		if (this.selectedPercent === percent) {
			this.selectedPercent = 0;
		} else {
			this.selectedPercent = percent;
		}
		this.customTip = 0
	}
}
