import ArticleCategory from './ArticleCategory';
import Hours from './Hours';
import Table from './Table';
import Assets from './Assets';
import Tag from './Tag';
import { CheckoutIntegrationType } from '../enums/CheckoutIntegrationType';
import Localization from './Localization';
import { LocationType } from '../enums/LocationType';
import SlotsConfig from './SlotsConfig';
import OptionGroup from './OptionGroup';
import { Moment } from 'moment';
import Offset from './Offset';
import { Legal } from './Legal';
import { ByType } from './ByType';
import { MovAndFee } from './MovAndFee';

export default class Venue {
	// tslint:disable-next-line:variable-name
	_id: string;
	articleCategories?: ArticleCategory[];
	address?: string;
	assets?: Assets;
	optionGroups: OptionGroup[] = [];
	cashDeliveryEnabled?: boolean;
	cashEnabled?: boolean;
	cashPreorderEnabled?: boolean;
	ccDeliveryEnabled?: boolean;
	ccEnabled?: boolean;
	ccPreorderEnabled?: boolean;
	checkoutIntegration?: CheckoutIntegrationType;
	ppV2Enabled: boolean;
	gpEnabled: boolean;
	apEnabled: boolean;
	ppFullIntegrationEnabled: boolean;
	city: Localization;
	currency: string;
	currencySymbol: string;
	deletedAt?: Date;
	deliveryEnabled?: boolean;
	customerGroup?: string;
	deliveryHours?: Hours[];
	distance?: number;
	cateringHoursTakeAway: Hours[];
	deliveryRadius?: number;
	email?: string;
	firstName?: string;
	firstPreorderOffset?: number;
	lastPreorderOffset?: number;
	preorderOffset?: number;
	isPublished?: boolean;
	isServiceActivated?: boolean;
	lastName?: string;
	legalForm?: string;
	location: { coordinates: number[]; type?: string };
	locType: LocationType;
	lunchroomUrl?: string;
	merchantName?: string;
	deliveryByRadius: boolean;
	vrPaymentEnabled: boolean;
	movDelivery?: {
		$numberDecimal: number;
	};
	name: string;
	openingHours?: Hours[];
	cateringHours?: Hours[];
	paymentEnabled?: boolean;
	phoneNumber?: string;
	posDeliveryEnabled?: boolean;
	posPreorderEnabled?: boolean;
	postalCode?: string;
	ppDeliveryEnabled?: boolean;
	ppEnabled?: boolean;
	ppPreorderEnabled?: boolean;
	preorderIn: boolean;
	preorderTable?: string;
	preorderTakeAway: boolean;
	preorderParkCollect: boolean;
	cateringDelivery: boolean;
	cateringTakeAway: boolean;
	readableId?: string;
	sbDeliveryEnabled?: boolean;
	sbEnabled?: boolean;
	sbPreorderEnabled?: boolean;
	sepaDeliveryEnabled?: boolean;
	sepaEnabled?: boolean;
	size?: string;
	standardOrders?: boolean;
	street?: string;
	number?: string;
	systemTable?: string;
	state?: string;
	country: string;
	tables?: Table[];
	tags?: Tag[];
	thumbUrl?: string;
	website?: string;
	slot: SlotsConfig;
	mappings: [
		{
			mappedArticle: string;
			main: string;
			groups: [
				{
					group: string;
					article: string;
				}
			];
		}
	];
	offsets: ByType<Offset>;
	deliveryFees: [
		{
			_id: string;
			from: {
				$numberDecimal: number;
			};
			fee: {
				$numberDecimal: number;
			};
		}
	];
	deliveryFeesPostalCodes: [
		{
			_id: string;
			postalCode: string;
			fee: {
				$numberDecimal: number;
			};
		}
	];
	nutritionTable: string;
	panicEndAt: Moment;
	legal: Legal;
	deliveryPostalCodes: string[];
	isPostalDelivery = false;
	movAndFee: MovAndFee;
	externalDeliveryService?: {
		icon?: Assets | null;
		link?: string;
		name?: string;
	};
}
