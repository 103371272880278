import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import Order from '../../../smoothr-web-app-core/models/Order';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {environment} from '../../../environments/environment';
import {numberToCurrency} from 'src/smoothr-web-app-core/utils/utils';
import {NavigationService} from 'src/app/services/navigation.service'


@Component({
	selector: 'app-to-order-button',
	templateUrl: './to-order-button.component.html',
	styleUrls: ['to-order-button.component.scss']
})
export class ToOrderButtonComponent {
	@Input() order: Order;

	constructor(private router: Router, private navigationService: NavigationService) {}

	get price(): number {
		if (!this.order) {
			return 0;
		}
		return OrderUtils.articleGroupsTotalPrice(
			this.order.orderedArticles,
			this.order.type,
			this.order.preorder.type,
			this.order.terminalorder ? this.order.terminalorder.type : null
		);
	}

	get formattedPrice(): string {
		return numberToCurrency(this.price, this.order.currency);
	}

	get active(): boolean {
		return this.price > 0;
	}

	async goToOrder() {
		if (this.active) {
		 	await this.navigationService.order();
		}
	}
}
