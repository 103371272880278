import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const lacantineCustomerGroup = 'lacantine';
export const customerGroup = lacantineCustomerGroup;

const supportEmail = {
	lacantine: 'lacantine@smoothr.de'
};
const firebaseConfig = {
	lacantine: {
		apiKey: 'AIzaSyD2lNupuxAWeV74e_11Bq_4hTf4z1n57bg',
		authDomain: 'lacantine-825f4.firebaseapp.com',
		projectId: 'lacantine-825f4',
		storageBucket: 'lacantine-825f4.appspot.com',
		messagingSenderId: '185404610970',
		appId: '1:185404610970:web:89a272b0023c238dc8c223',
		measurementId: 'G-QZSQ48BC9E'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyCHgpycB0gFwahyYXgLqA3fHreIeinNcPA',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	countryList: ['de'],
	gaId: ''
};
