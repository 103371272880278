import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import { NavigationService } from 'src/app/services/navigation.service';


@Component({
	selector: 'app-privacy',
	templateUrl: './privacy.page.html',
	styleUrls: ['privacy.page.scss']
})
export class PrivacyPage {
	static url = 'privacy';
	constructor(private repository: RepositoryService, private navigationService: NavigationService) {}



	async goBack() {
		if (this.repository._order === null) {
			await this.navigationService.home()
		} else {
			await this.navigationService.menu()
		}
	}
}
