import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';
import Localization from '../models/Localization';
import { Observable } from 'rxjs';

@Pipe({ name: 'langObservable' })
export class LangObservablePipe implements PipeTransform {
	constructor(private translate: TranslateService) {
	}

	transform(value: Localization): Observable<string> {
		return this.translate.onLangChange.pipe(
			startWith(''),
			map(() => {
				if (!value) {
					return '';
				}
				if (value[this.translate.currentLang]) {
					return value[this.translate.currentLang];
				} else {
					return value.de;
				}
			}),
		);
	}
}
