import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {HomePage} from '../home/home.page';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
	selector: 'app-maintenance-page',
	templateUrl: './maintenance.page.html',
	styleUrls: ['maintenance.page.scss']
})
export class MaintenancePage implements OnInit {
	static url = 'maintenance';

	loading = false;

	constructor(private navigationService: NavigationService) {}

	// static async navigate(router: Router) {
	// 	await router.navigateByUrl(MaintenancePage.url, {
	// 		skipLocationChange: true,
	// 		replaceUrl: false
	// 	});
	// }

	ngOnInit() {}

	async reload() {
		this.loading = true;
		try {
			if (!(await Api.isMaintenanceActive())) {
				await this.navigationService.home();
			}
		} catch (e) {}
		this.loading = false;
	}
}
