import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import validator from 'validator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AccountPage} from '../account/account.page';
import {EmailConfirmationPage} from '../email-confirmation/email-confirmation.page';
import {Api} from '../../../smoothr-web-app-core/api/api';
import {environment} from '../../../environments/environment';
import {Gender} from 'src/smoothr-web-app-core/enums/Gender';
import {HomePage} from '../home/home.page';
import {MenuPage} from '../menu/menu.page';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {SignInPage} from '../sign-in/sign-in.page';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import {AuthStrategy} from '../../../smoothr-web-app-core/models/AuthStrategy';
import {axiosErrorToMessage} from '../../../smoothr-web-app-core/utils/utils';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {FormBuilder, FormGroup, Validators, ValidatorFn} from '@angular/forms';
import {NavigationService} from 'src/app/services/navigation.service';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.page.html',
	styleUrls: ['sign-up.page.scss']
})
export class SignUpPage implements OnDestroy, OnInit {
	static url = 'sign-up';

	showRePassword = false;
	showPassword = false;
	isValidEmail = true;
	isValidName = true;
	isValidPassword = true;
	isValidRePassword = true;
	venue: Venue;
	email = 'email';
	password = 'password';
	rePassword = 'rePassword';
	fullName = 'fullName';
	agbChecked = 'agbChecked';
	loading = false;
	// Gender = Gender;
	// gender = 'gender';
	signUpForm: FormGroup;

	private readonly subscription: Subscription;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private fb: FormBuilder,
		private navigationService: NavigationService
	) {
		if (repository.customer && !repository.customer.isAnonymous) {
			this.navigationService.account();
		}
	}
	ngOnInit() {
		this.createFormSignUp();
	}

	createFormSignUp() {
		const formControlNames: any = {};
		// formControlNames[this.gender] = ['', [Validators.required]];
		formControlNames[this.fullName] = ['', [Validators.required, Validators.pattern(/\w+\s\w+/)]];
		formControlNames[this.email] = [
			'',
			[
				Validators.required,
				Validators.pattern(
					"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
				)
			]
		];
		formControlNames[this.agbChecked] = [false, [Validators.required, Validators.requiredTrue]];
		formControlNames[this.password] = [
			'',
			[Validators.required, Validators.minLength(8), Validators.pattern(/\d+/), Validators.pattern(/[A-Z]+/)]
		];

		formControlNames[this.rePassword] = ['', [Validators.required, this._isPasswordMatch()]];
		this.signUpForm = this.fb.group(formControlNames);
	}

	private _isPasswordMatch(): ValidatorFn {
		return () => {
			const pass = this.signUpForm?.get('password')?.value;
			const confirmPass = this.signUpForm?.get('rePassword')?.value;
			return pass === confirmPass ? null : {notSame: true};
		};
	}

	isValid(controlName?: string): boolean {
		if (controlName) {
			return this.signUpForm.get(controlName)?.hasError('required') && this.signUpForm.get(controlName)?.touched;
		}

		return this.signUpForm.invalid;
	}

	isValidByPattern(controlName?: string): boolean {
		if (controlName) {
			return this.signUpForm.get(controlName)?.errors && !this.signUpForm.get(controlName)?.hasError('required');
		}
		return this.signUpForm.invalid;
	}

	getControlValue(controlName?: string) {
		if (controlName) {
			return this.signUpForm?.get(controlName)?.value;
		}
		return '';
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}

	async signUp() {
		if (!this.loading) {
			this.loading = true;
			try {
				await Api.signUpWithCredentials(
					{
						name: this.getControlValue('fullName'),
						gender: Gender.male,
						email: this.getControlValue('email'),
						preferredVenues: []
					},
					this.getControlValue('password')
				);
				await this.navigationService.emailConfirmation();
			} catch (e) {
				if (e.response.data.name === 'UserAlreadyExistsError') {
					this.isValidEmail = false;
				}
				this.snackbarCtrl.open(axiosErrorToMessage(this.translate, e));
			}
			this.loading = false;
		}
	}

	async signIn() {
		await this.navigationService.signIn();
	}

	setEmailValid() {
		this.isValidEmail = true;
	}

	setNameValid() {
		this.isValidName = true;
	}

	setPasswordValid() {
		this.isValidPassword = true;
		this.isValidRePassword = true;
	}

	async signUpWithGoogle() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Google');
				const result = await Api.signIn(AuthStrategy.GOOGLE);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithFacebook() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Facebook');
				const result = await Api.signIn(AuthStrategy.FACEBOOK);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithApple() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Apple');
				const result = await Api.signIn(AuthStrategy.APPLE);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	openTerms() {
		window.open('/tos', '_blank');
	}

	openPrivacy() {
		window.open('/privacy', '_blank');
	}

	goBack() {
		if (this.repository._order === null) {
			this.navigationService.home();
		} else {
			this.navigationService.menu();
		}
	}
}
