import {Component, Input, OnInit} from '@angular/core';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {PaymentMethod} from '../../../smoothr-web-app-core/enums/PaymentMethod';
import {environment} from '../../../environments/environment';

// @ts-ignore
const applePayAvailable = !!window.ApplePaySession;

@Component({
	selector: 'app-payment-methods-display',
	templateUrl: './payment-methods-display.component.html',
	styleUrls: ['payment-methods-display.component.scss']
})
export class PaymentMethodsDisplayComponent implements OnInit {
	customerGroup = environment.customerGroup;
	availablePaymentMethods = [];
	environment = environment;
	constructor() {}

	@Input()
	set venue(value: Venue) {
		if (!value) {
			this.availablePaymentMethods = [];
			return;
		}
		this.availablePaymentMethods = [];
		if (value.ccEnabled) {
			this.availablePaymentMethods.push(PaymentMethod.MASTERCARD);
			this.availablePaymentMethods.push(PaymentMethod.VISA);
		}
		if (value.gpEnabled) {
			this.availablePaymentMethods.push(PaymentMethod.GPAY);
		}
		if (value.apEnabled && applePayAvailable) {
			this.availablePaymentMethods.push(PaymentMethod.APAY);
		}
		if (value.sbEnabled) {
			this.availablePaymentMethods.push(PaymentMethod.SOFORTBANKING);
		}
		if (value.ppEnabled) {
			this.availablePaymentMethods.push(PaymentMethod.PAYPAL);
		}
	}

	ngOnInit() {}
}
