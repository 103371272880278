import {Component, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from 'src/app/services/navigation.service';
import {environment} from 'src/environments/environment';
import {Api} from 'src/smoothr-web-app-core/api/api';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {OrderUtils} from 'src/smoothr-web-app-core/utils/order-utils';
import {getPrice, numberToCurrency} from 'src/smoothr-web-app-core/utils/utils';
import {HomePage} from '../home/home.page';

@Component({
	selector: 'app-order-success',
	templateUrl: './order-success.component.html',
	styleUrls: ['./order-success.component.scss']
})
export class OrderSuccessComponent extends RepositoryDirective implements OnInit {
	static urlWithOrderParams = 'order/:orderId';
	loading = false;
	orderId = '';
	orderVenue: Venue;
	error: any;
	environment = environment;
	numberToCurrency = numberToCurrency;
	OrderUtils = OrderUtils;
	verifiedOrder: Order;
	constructor(
		private route: ActivatedRoute,
		protected repository: RepositoryService,
		private translate: TranslateService,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private navService: NavigationService
	) {
		super(repository);
	}
	async onInitFinish() {
		super.onInitFinish();
		console.log(this.verifiedOrder);
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			console.log({params});
			this.orderId = params?.orderId;
			this.reloadOrder();
		});
	}

	ionViewDidEnter() {
		this.repository.order.emit(null);
	}

	home() {
		if (this.loading) {
			return;
		}
		if (this.error) {
			this.reloadOrder();
			return;
		}

		this.loading = true;
		this.navService.home();
	}

	async reloadOrder(attemp: number = 0, error = null) {
		this.loading = true;
		this.error = false;

		if (attemp > 5) {
			this.error = error ? error : this.translate.instant('payment_success_page.payment_error');
			this.snackbarCtrl.open(error, null, {
				duration: 2000
			});
			this.loading = false;
			return;
		}

		try {
			const response = (await Api.getOrder(this.orderId)).data;
			this.verifiedOrder = response;
			if (response.venue) {
				this.orderVenue = (await Api.getLazyVenue(response.venue)).data;
			}
			this.loading = false;
		} catch (e) {
			await this.reloadOrder(attemp + 1, e);
			console.log(e);
			this.loading = false;
		}
	}
	checkArticleTagEmpty(event: any) {
		if (event.article.tags?.length > 0) {
			return event.article.tags.some(it => it === 'empty');
		} else {
			return false;
		}
	}
	priceOfOption(articleGroup: ArticleGroup, articleOption: ArticleOption): number {
		const price = getPrice(articleOption.article, OrderType.PREORDER, this.verifiedOrder?.preorder?.type);
		if (OrderUtils.isBogoOrFreeArticlePromo(this.verifiedOrder) && articleGroup.isPromo) {
			return 0;
		}
		return price;
	}
}
