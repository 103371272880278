import { Gender } from '../enums/Gender';
import { Moment } from 'moment/moment';

export default class Customer {
	_id?: string;
	name?: string;
	street?: string;
	city?: string;
	postalCode?: string;
	companyName?: string;
	preferredVenues?: string[];
	number?: string;
	gender?: Gender;
	emailVerifiedAt?: Moment;
	phone?: string;
	email?: string;
	country?: string;
	floor?: string;
	subcard?: string;
	loyaltyNumber?: string;
	uid: string;
	isAnonymous: boolean;
}
