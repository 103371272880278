export enum CateringOrderType {
	TAKE_AWAY = 'take_away',
	INSIDE = 'inside',
	DELIVERY = 'delivery'
}

export const CateringOrderTypeArray: CateringOrderType[] = [
	CateringOrderType.TAKE_AWAY,
	CateringOrderType.DELIVERY,
];
